<template lang="pug">
  Modal.modal--reorder(title="Reorder Collection", v-bind="$attrs")
    draggable.items(v-model="collection.contents", @end="onEnd", ghost-class="ghost")
      div.item(v-for="(content, index) in collection.contents", :key="content.id", :class="`item--${content.block_type}`")
        div.item__content
          div.flex.space-x-3.overflow-hidden.items-center
            span.flex-shrink-0.w-6: Icon(:icon="blockIcon(content)", class="item__icon")
            component(:is="getComponent(content.block_type)", v-bind="content")
        div.item__handle: Icon(icon="arrows-v")
</template>

<script>
import draggable from "vuedraggable"
import ReorderBlock from "./ReorderBlocks/ReorderBlock.vue"
import ReorderCTABlock from "./ReorderBlocks/ReorderCTABlock.vue"
import ReorderNoteBlock from "./ReorderBlocks/ReorderNoteBlock.vue"
import ReorderImageBlock from "./ReorderBlocks/ReorderImageBlock.vue"
import ReorderItineraryBlock from "./ReorderBlocks/ReorderItineraryBlock.vue"

export default {
  components: {
    draggable
  },
  props: {
    collection: Object
  },
  methods: {
    blockIcon(content) {
      switch (content.block_type) {
        case "content":
          return content.type.icon
        default:
          return `block-${content.block_type}`
      }
    },
    getComponent(type) {
      switch (type) {
        case "cta":
          return ReorderCTABlock
        case "image":
          return ReorderImageBlock
        case "itinerary":
          return ReorderItineraryBlock
        case "note":
          return ReorderNoteBlock
        default:
          return ReorderBlock
      }
    },
    async onEnd(evt) {
      if (evt.oldIndex === evt.newIndex) return

      let order = []

      this.collection.contents.forEach((content, index) => {
        order.push({ id: content.id, order: index + 1 })
      })

      await this.$api.put(`collections/${this.collection.id}/reorder`, { order })
      this.$notification.success(`The order of this collection has been updated.`)
    }
  }
}
</script>

<style>
.modal--reorder {
  @apply overflow-hidden;

  .modal-header {
    @apply py-10;
  }

  &.has-title .modal-body {
    @apply bg-gray-200 pt-16;
  }

  .item {
    @apply flex items-center justify-between rounded-md bg-gray-100 shadow;

    & + .item {
      @apply mt-2;
    }

    &__handle {
      @apply rounded-tr-md rounded-br-md px-3 py-4 text-supplement;

      &:hover {
        @apply cursor-move;
      }
    }

    &__content {
      @apply flex flex-grow items-center overflow-hidden rounded-l-md border-l-4 bg-white px-6 py-4 font-medium;
    }

    &__icon {
      @apply mt-1 text-lg text-primary;
    }

    &--content .item__content {
      @apply border-blocks-content;

      .item__icon {
        @apply text-blocks-content;
      }
    }

    &--cta .item__content {
      @apply border-blocks-cta;

      .item__icon {
        @apply text-blocks-cta;
      }
    }

    &--form .item__content {
      @apply border-blocks-form;

      .item__icon {
        @apply text-blocks-form;
      }
    }

    &--heading .item__content {
      @apply border-blocks-heading;

      .item__icon {
        @apply text-blocks-heading;
      }
    }

    &--image .item__content {
      @apply border-blocks-image;

      .item__icon {
        @apply text-blocks-image;
      }
    }

    &--note .item__content {
      @apply border-blocks-note;

      .item__icon {
        @apply text-blocks-note;
      }
    }
  }
}
</style>
