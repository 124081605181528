<template>
  <img :src="image.thumb_url" class="h-8 w-12 rounded-md object-cover" />
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true
    }
  }
}
</script>
